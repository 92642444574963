<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2">
        A solution is created when a small amount of
        <v-select
          v-model="inputs.input1"
          class="ml-2 mr-2"
          style="display: inline-block; max-width: 200px"
          :items="itemsShuffled"
          item-text="text"
          item-value="value"
          label="A"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
        dissolves in a large amount of
        <v-select
          v-model="inputs.input2"
          class="ml-2"
          style="display: inline-block; max-width: 200px"
          :items="itemsShuffled"
          item-text="text"
          item-value="value"
          label="B"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
        .
      </p>

      <p class="mb-2">
        When a solution's concentration is reported in moles of
        <v-select
          v-model="inputs.input3"
          class="ml-2 mr-2"
          style="display: inline-block; max-width: 200px"
          :items="itemsShuffled"
          item-text="text"
          item-value="value"
          label="C"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
        per liter of solution, the concentration is reported as a
        <v-select
          v-model="inputs.input4"
          class="ml-2"
          style="display: inline-block; max-width: 200px"
          :items="itemsShuffled"
          item-text="text"
          item-value="value"
          label="D"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
        .
      </p>

      <p class="mb-2">
        When a solution's concentration is reported in moles of
        <v-select
          v-model="inputs.input5"
          class="ml-2 mr-2"
          style="display: inline-block; max-width: 200px"
          :items="itemsShuffled"
          item-text="text"
          item-value="value"
          label="E"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
        per
        <v-select
          v-model="inputs.input6"
          class="ml-2 mr-2"
          style="display: inline-block; max-width: 200px"
          :items="itemsShuffled"
          item-text="text"
          item-value="value"
          label="F"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
        of solvent, the concentration is reported as a
        <v-select
          v-model="inputs.input7"
          class="ml-2"
          style="display: inline-block; max-width: 200px"
          :items="itemsShuffled"
          item-text="text"
          item-value="value"
          label="G"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
        .
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '../StembleLatex.vue';
import {seededShuffle} from '@/courses/utils/seededShuffle';

export default {
  name: 'ChemUCI1LDA1S1Q6',
  components: {
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
        input5: null,
        input6: null,
        input7: null,
      },
      items: [
        {text: 'solute', value: 'solute'},
        {text: 'solvent', value: 'solvent'},
        {text: 'kilogram', value: 'kilogram'},
        {text: 'gram', value: 'gram'},
        {text: 'molarity', value: 'molarity'},
        {text: 'molality', value: 'molality'},
        {text: 'normality', value: 'normality'},
      ],
    };
  },
  computed: {
    seed() {
      return this.$gate.user?.id || 1;
    },
    itemsShuffled() {
      return seededShuffle(this.items, this.seed);
    },
  },
};
</script>
<style scoped></style>
